<template>
  <ion-page id="marketplace-page" class="page bg-transparent">
    <div class="d-flex justify-content-between align-items-center flex-wrap py-3">
      <div class="title">Marketplace</div>

      <div v-if="isAuthenticated" class="ml-auto">
        <ion-button color="primary" class="new-listing-btn" @click="createListing">New Listing</ion-button>
        <router-link :to="{ name: 'manage-mp-listings' }"
          ><ion-button color="transparent" class="new-listing-btn">Manage Listings</ion-button></router-link
        >
      </div>

      <div v-else>
        Have something to sell?
        <a
          href="https://www.waitlistr.com/lists/1393c853/character-storage-and-development-built-with-for-the-community"
          target="_blank"
          >Apply here!</a
        >
      </div>
    </div>

    <top-banner banner-name="marketHomeHeader" />
    <MarketplaceTabs :disabled="isLoading" :tabs="tabs" @tabChanged="tabChanged" />

    <div v-if="get(allListings, 'length')" class="row m-0 w-100">
      <Grid :xl="4" :lg="3" :md="2" :sm="2" :scrollable-on-mobile="false">
        <MarketplaceCard
          :openBlank="true"
          class="grid-item"
          v-for="(listing, index) in allListings"
          :listing="listing"
          :key="index"
        />
      </Grid>
    </div>
    <div v-if="nextPageExists && !isLoading" class="d-flex justify-content-center mb-4">
      <h5 class="clickable-item-hov" @click="requestLoadMore">Load More</h5>
    </div>

    <div v-if="isLoading" class="d-flex justify-content-center align-items-center">
      <ChLoading size="lg" class="spinner" />
    </div>
    <div v-else-if="!get(allListings, 'length')" class="text-center my-4">No Active Listings.</div>
  </ion-page>
</template>

<script lang="ts" setup>
import { alertController } from '@ionic/vue';
import TopBanner from '../home/components/TopBanner.vue';
import MarketplaceTabs from './MarketplaceTabs.vue';
import { toast } from '@/shared/native';
import { getMarketplaceListings, getNextPage } from '@/shared/actions/marketplace';
import type { Paging } from '@/shared/types/static-types';
import { updateUserdata } from '@/shared/actions/users';
import { marketplaceTabs } from '@/shared/statics/tabs';
import constants from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';
import store from '@/shared/store';

import MarketplaceCard from '@/shared/components/storage/MarketplaceCard.vue';
import Grid from '@/shared/components/storage/Grid.vue';

const allListings = ref<any[]>([]);
const isLoading = ref(true);
const paging = ref<Paging | null>(null);
const tabs = ref(marketplaceTabs);

const route = useRoute();
const url = ref(`https://characterhub.com${route.path}`);
const pageTitle = ref('Marketplace - CharacterHub');
const description = ref(`Show more listings on CharacterHub!`);
const picture = ref('');

useChHead(pageTitle, pageTitle, description, url, picture);

const isAuthenticated = computed(() => {
  const { isAuthenticated } = authStore();
  return isAuthenticated.value;
});

const getUserByUsername = (args: any) => store.dispatch('UsersModule/getUserByUsername', args);

const user = computed(() => {
  const { user } = authStore();
  return user.value;
});

const tabChanged = async (tabIndex: number) => {
  const router = useRouter();
  const tab = tabs.value[tabIndex];
  allListings.value = [];
  if (!tab.value) {
    // fetch all
    await fetchMPListings();
  } else if (tab.value === 'characterhub') {
    if (isAuthenticated.value) router.push({ name: 'shop' });
    else {
      toast.show('Please sign in to view the CharacterHub shop.', 'nonative', 'primary');
      router.push({ name: 'login' });
    }
  } else if (tab.value === 'free') {
    await fetchMPListings(1, { free: 1 });
  } else {
    await fetchMPListings(1, { category: tab.value });
  }
};

const fetchMPListings = async (page = 1, params = {}) => {
  isLoading.value = true;

  try {
    const { results, ...inPaging } = await getMarketplaceListings(page, constants.defaultPageSize, params);
    allListings.value = results;
    paging.value = inPaging;
  } catch (e) {
    await toast.show('Could not load listings. Please try again.', 'nonative', 'danger');
  } finally {
    isLoading.value = false;
  }
};

const requestLoadMore = async () => {
  isLoading.value = true;

  try {
    const { results, ...inpaging } = await getNextPage(paging.value!);
    allListings.value = allListings.value.concat(results);
    paging.value = inpaging;
  } catch (e) {
    await toast.show('Could not load listings. Please try again.', 'nonative', 'danger');
  }

  isLoading.value = false;
};

const nextPageExists = computed(() => {
  return !!paging.value?.next;
});

const createListing = async () => {
  if (!user.value.mp_agreement) {
    try {
      const alert = await alertController.create({
        cssClass: 'marketplace-post-alert',
        header: 'Welcome to marketplace! A few notes:',
        message: `<ul class="text-black">
  <li>Payments will be through PayPal when you cash out (PayPal fees apply). <b>You must have PayPal!</b></li>
  <li>Minimum cash out is $10</li>
  <li>You get the <b>FULL amount you make</b>. We take a small fee from the buyer when they order.</li>
  <li>Do not sell anything that is not yours or is copyrighted. No AI related works or services allowed.</li>
  <li>Read about all the rules and suggestions <b><a rel="nofollow" class="link" href="https://docs.google.com/document/d/15R6A5rNy9p_Ue0YmxN2nVKWd61LZvyR9nTNXXiZmRW4/edit?usp=sharing" target="_blank" class="text-black clickable-item-hov">here</a></b>.</li>
</ul>`,
        buttons: [
          {
            text: 'Start Posting',
            id: 'confirm-button',
            role: 'ok',
            cssClass: 'text-primary',
          },
        ],
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      const router = useRouter();

      if (role === 'ok') {
        const { updateLoggedInUserData } = authStore();
        updateLoggedInUserData({ mp_agreement: true });
        await updateUserdata(user.value.id, { mp_agreement: true });
        router.push({ name: 'create-mp-listing' });
      }
    } catch (err) {
      toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    }
  } else {
    const router = useRouter();
    router.push({ name: 'create-mp-listing' });
  }
};

onMounted(async () => {
  fetchMPListings();

  if (user.value.id) {
    await getUserByUsername(user.value.username);
  }
});
</script>

<style lang="sass" scoped>
.name
  font-size: 18px
  font-weight: bold
  color: #737373
  cursor: pointer
  .name:hover
    opacity: 0.7

.new-listing-btn
  color: #214163
  font-size: 14px
  font-weight: bold
.title
  font-size: 24px
  font-weight: bold

.one-listing
  img
    height: 130px
    width: 100vh !important
    object-fit: cover

.baseprice
  font-size: 18px
  font-weight: bold

.listing-card
  height: calc(100% - 1.5rem) !important
</style>
