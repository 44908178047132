<template>
  <div class="text-center">
    <ion-segment 
      :value="activeIndex" 
      class="tabs compact no-shadow bg-transparent taller" 
      mode="ios"
      scrollable
    >
      <ion-segment-button
        v-for="(tab, index) of tabs"
        :key="index"
        :color="activeIndex === index ? 'primary' : 'medium'"
        class="shrink"
        :disabled="disabled"
        @click="segmentChanged(index)"
        :value="index"
      >
        <ion-label>{{ tab.name }}</ion-label>
      </ion-segment-button>
    </ion-segment>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Tab } from '@/shared/types/static-types';

@Options({
  name: 'MarketplaceTabs',
  components: {},
})
export default class MarketplaceTabs extends Vue {
  @Prop({ type: Array }) public tabs!: Tab[];
  @Prop({ type: Boolean }) public disabled!: Boolean;

  public activeIndex = 0;

  public segmentChanged(index: number) {
    this.activeIndex = index;
    this.$emit('tabChanged', index);
  }
}
</script>

<style scoped lang="sass">
.shrink
  flex: none !important
  height: 50px !important
  min-height: 50px !important
.taller
  height: 55px !important
  min-height: 55px !important
ion-label
  letter-spacing: 0.02rem
  text-wrap: wrap
  line-height: 1.1rem
</style>
